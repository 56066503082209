@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.main {
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin-bottom: 60px;

  &FullHeight {
    height: 100vh;
  }
  @media screen and (min-width: vars.$large) {
    .mainContent {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    &FullHeight {
      .mainContent {
        margin-top: initial;
        margin-bottom: initial;
      }
    }
  }
}

.mainContent {
  flex: 1 1 100%;
  justify-content: center;
}

.stickyHeaderWrapper {
  // @todo: transition is not working
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
  contain: strict;

  &_shown {
    contain: unset;
    opacity: 1;
    pointer-events: initial;
  }
}

.mainArrowDown {
  display: none;
  font-size: 1.25rem;
  width: 100%;
  contain: content;

  @media screen and (min-width: vars.$medium) {
    align-items: center;
    color: colors.$white;
    display: flex;
    flex: 0 0 50px;
    justify-content: center;
  }

  svg {
    animation-direction: alternate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: fadeArrow;
  }
}

@keyframes fadeArrow {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.slogan {
  color: colors.$homeHeaderColor;
  text-align: center;
  margin-top: 7rem;

  @media screen and (min-width: vars.$medium) {
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: vars.$extra-large) {
    margin-top: 0;
  }
}

.sloganBoldPart {
  font-stretch: condensed;
  font-weight: bold;
  display: block;

  @media screen and (min-width: vars.$medium) {
    margin-right: 7px;
  }
}

.searchBarContainer {
  display: flex;
  flex-flow: column;
  max-width: 964px;
  width: 100%;

  @media screen and (min-width: vars.$extra-large) {
    box-sizing: content-box;
    padding: 0 20px;
    margin: 90px auto 0;
  }
}

.popularSearchTermsContainer {
  margin-top: 15px;
  width: 100%;
}

.popularSearchTerms {
  display: none;
  list-style-type: none;

  @media screen and (min-width: vars.$medium) {
    align-items: center;
    color: colors.$homeHeaderColor;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    margin: 0;
  }

  @media screen and (min-width: vars.$extra-large) {
    padding: 0;
    margin-top: 0px;
  }
}

.popularSearchTerm {
  list-style-type: none;

  color: colors.$homeHeaderColor;

  &:hover {
    color: colors.$primary-color;
  }
}

.logoReelContainer {
  margin-top: 124px;

  @media screen and (min-width: vars.$medium) {
    margin-top: 53px;
    margin-bottom: 106px;
  }

  @media screen and (min-width: vars.$large) {
    margin-top: 178px;
  }

  @media screen and (min-width: vars.$extra-large) {
    margin-left: -24px;
    margin-top: 170px;
    margin-bottom: 106px;
  }
}

.statsContainer {
  display: none;

  @media screen and (min-width: vars.$medium) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 70px;
    height: 50px;
    width: 100%;
  }

  @media screen and (min-width: vars.$large) {
    margin-top: 70px;
  }
}

.stats {
  align-items: center;
  color: colors.$homeHeaderColor;
  display: flex;
  flex-direction: column;
  font-size: 1em;
  line-height: 1rem;
  margin-right: 137px;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }
}

.statsValue {
  font-size: 2rem;
  font-stretch: condensed;
  font-weight: bold;
  line-height: 2rem;

  @media screen and (min-width: vars.$large) {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }
}

.hiddenSearchBar {
  opacity: 0;
}
