@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  -ms-overflow-style: none;
  display: flex;
  flex: 0 0 100%;
  flex-flow: row nowrap;
  list-style-type: none;
  margin: 0;
  margin-left: -10px;
  margin-right: -10px;
  overflow: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  contain: content;

  &:before,
  &:after {
    content: '';
    flex: 0 0 10px;
    scroll-snap-align: start;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:after {
    flex: 0 0 1px;
  }

  @media screen and (min-width: vars.$large) {
    margin-left: auto;
    margin-right: auto;
    overflow: initial;

    &:before,
    &:after {
      display: none;
    }
  }

  @media screen and (min-width: vars.$extra-large) {
    padding: 8px 24px;
    overflow: initial;
    box-sizing: border-box;
    width: 1008px;
    max-width: initial;
    justify-content: center;
  }
}

.logo {
  background-color: colors.$white;
  border-radius: 4px;
  margin-right: 10px;
  scroll-snap-align: start;
  transition: transform 0.2s ease-in-out;
  will-change: transform;

  @media screen and (min-width: vars.$large) {
    &:hover {
      transform: scale(1.2);
    }
  }

  a {
    align-items: center;
    display: flex;
    flex: 0 0 74px;
    height: 64px;
    justify-content: center;
    padding: 5px;
    width: 74px;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: vars.$small) {
    margin-right: 30px;

    a {
      flex: 0 0 135px;
      height: 80px;
      width: 135px;
    }
  }
}
