@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.root {
  padding-bottom: 40px;
}

.slidersRoot {
  margin: 0;
  margin-top: -10px;
}

.slidesWrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;

  @media screen and (min-width: vars.$large) {
    gap: vars.$gutter;
  }
}

.productCardWrapper {
  padding-top: 10px;
}

.productCard {
  width: 200px;

  @media screen and (min-width: vars.$medium) {
    width: 227px;
  }
}

.sectionHeader {
  display: grid;
  grid-template-columns: auto max-content;
  margin-bottom: vars.$gutter;
  align-items: center;
}
