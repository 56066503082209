.backgroundImage {
  width: 0;
  height: 0;
  overflow: visible;

  &  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
}
