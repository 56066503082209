@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.root {
  padding-bottom: 40px;
}

.wrapper {
  grid-template-areas:
    'product'
    'productWidget';
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @media screen and (min-width: vars.$extra-extra-large) {
    grid-template-areas: 'product productWidget';
    grid-template-columns: 2fr 1fr;
  }
}

.reversedWrapper {
  grid-template-areas:
    'product'
    'productWidget';
  grid-template-columns: 1fr;
  display: grid;
  gap: 32px;

  @media screen and (min-width: vars.$extra-extra-large) {
    grid-template-areas: 'productWidget product';
    grid-template-columns: 1fr 2fr;
  }
}

.sliderContainer {
  display: block;
  grid-area: product;
}

.productList {
  display: grid;
  grid-template-columns: repeat(2, minmax(168px, 1fr));
  row-gap: 24px;
  column-gap: 8px;

  @media screen and (min-width: vars.$medium) {
    grid-template-columns: repeat(3, minmax(230px, 1fr));
    column-gap: 16px;
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    grid-template-columns: repeat(4, minmax(216px, 1fr));
    row-gap: 32px;
    column-gap: 32px;
  }
}

.productWrapper {
  grid-area: productWidget;
  @media screen and (min-width: vars.$extra-extra-large) {
    grid-area: productWidget;
  }
}

.productCard {
  &:nth-child(n + 7) {
    display: none;
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    &:nth-child(n + 7) {
      display: grid;
    }
    &:nth-child(n + 9) {
      display: none;
    }
  }
}

.sectionHeader {
  display: grid;
  grid-template-columns: auto max-content;
  margin-bottom: vars.$gutter;
  align-items: center;
}
