@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.root {
  width: 100%;
  border-radius: 4px;
  padding: 40px 24px 38px 24px;
  flex-direction: column;

  @media screen and (min-width: vars.$extra-extra-large) {
    display: flex;
  }
}

.orange {
  background-color: #fae3ac;
  .subTitle {
    color: #bf7e00;
  }
}

.green {
  background-color: #ccdfce;
  .subTitle {
    color: #4c6f51;
  }
}

.blue {
  background-color: rgba(0, 137, 255, 0.15);
  .subTitle {
    color: var(--light-blue);
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  font-stretch: condensed;
  line-height: 22px;
}

.seeAll {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #2a2a2a;
  cursor: pointer;
}

.subTitle {
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.4px;
  font-size: 36px;
  font-stretch: condensed;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 20px;
  margin-top: 24px;
}

.productCard {
  width: calc(50% - 12px);
  margin-bottom: 0px !important;

  @media screen and (min-width: vars.$medium) {
    width: calc(25% - 24px);
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    width: calc(50% - 12px);
  }
}

.productPrice {
  border-bottom: none;
}
